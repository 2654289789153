import type { Language, PrismTheme } from "prism-react-renderer";
import Highlight, { defaultProps } from "prism-react-renderer";
import { forwardRef, useCallback, useState } from "react";
import { cn } from "~/utils/cn";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger } from
"../primitives/Tooltip";
import {
  ClipboardDocumentCheckIcon,
  ClipboardIcon } from
"@heroicons/react/24/solid";

//This is a fork of https://github.com/mantinedev/mantine/blob/master/src/mantine-prism/src/Prism/Prism.tsx
//it didn't support highlighting lines by dimming the rest of the code, or animations on the highlighting

type CodeBlockProps = {
  /** Code which will be highlighted */
  code: string;

  /** Programming language that should be highlighted */
  language?: Language;

  /** Show copy to clipboard button */
  showCopyButton?: boolean;

  /** Display line numbers */
  showLineNumbers?: boolean;

  hideLineNumbersOnSmallDevices?: boolean;

  removeBorder?: boolean;

  /** Starting line number */
  startingLineNumber?: number;

  /** Highlight line at given line number with color from theme.colors */
  highlightedRanges?: [number, number][];

  /** Add/override classes on the overall element */
  className?: string;

  /** Add/override code theme */
  theme?: PrismTheme;

  /** Max height */
  maxCodeHeight?: string;

  /** Whether to show the chrome, if you provide a string it will be used as the title, */
  showChrome?: boolean;

  /** filename */
  fileName?: string;

  /** text size */
  textSize?: "xs" | "sm" | "base";
};

const dimAmount = 0.5;
const defaultTheme: PrismTheme = {
  plain: {
    color: "#9C9AF2",
    backgroundColor: "#121317"
  },
  styles: [
  {
    types: ["comment", "prolog", "doctype", "cdata"],
    style: {
      color: "#5F6570" // comment
    }
  },
  {
    types: ["punctuation"],
    style: {
      color: "#878C99" // brackets of XML/HTML tags
    }
  },
  {
    types: [
    "property",
    "tag",
    "boolean",
    "number",
    "constant",
    "symbol",
    "deleted"],

    style: {
      color: "#9B99FF" // variable.language, constant.language, entity.name.tag
    }
  },
  {
    types: ["selector", "attr-name", "string", "char", "builtin", "inserted"],
    style: {
      color: "#AFEC73" // string, meta.embedded.assembly
    }
  },
  {
    types: ["operator", "entity", "url"],
    style: {
      color: "#D4D4D4" // Reset JavaScript string interpolation expression
    }
  },
  {
    types: ["variable"],
    style: {
      color: "#CCCBFF" // variable.other.constant, variable.other.enummember
    }
  },
  {
    types: ["atrule", "attr-value", "keyword"],
    style: {
      color: "#E888F8" // keyword.control, keyword.operator.wordlike
    }
  },
  {
    types: ["function", "class-name"],
    style: {
      color: "#D9F07C" // support.function, entity.name.function
    }
  },
  {
    types: ["regex"],
    style: {
      color: "#d16969" // constant.regexp
    }
  },
  {
    types: ["important", "bold"],
    style: {
      fontWeight: "bold" // markup.bold
    }
  },
  {
    types: ["italic"],
    style: {
      fontStyle: "italic" // markup.italic
    }
  },
  {
    types: ["namespace"],
    style: {
      opacity: 0.7 // entity.name.namespace
    }
  },
  {
    types: ["deleted"],
    style: {
      color: "#F85149" // markup.deleted
    }
  },
  {
    types: ["boolean"],
    style: {
      color: "#9B99FF" // constant.language.boolean
    }
  },
  {
    types: ["char"],
    style: {
      color: "#b5cea8" // constant.character
    }
  },
  {
    types: ["tag"],
    style: {
      color: "#D7BA7D" // entity.name.tag.css
    }
  },
  {
    types: ["keyword.operator"],
    style: {
      color: "#8271ED" // keyword.operator
    }
  },
  {
    types: ["meta.template.expression"],
    style: {
      color: "#d4d4d4" // Reset JavaScript string interpolation expression
    }
  }]

};

export const CodeBlock = forwardRef<HTMLDivElement, CodeBlockProps>(
  (
  {
    showCopyButton = true,
    showLineNumbers = true,
    removeBorder = false,
    startingLineNumber = 1,
    highlightedRanges,
    code,
    className,
    language = "typescript",
    theme = defaultTheme,
    maxCodeHeight,
    showChrome = false,
    fileName,
    textSize = "sm",
    hideLineNumbersOnSmallDevices,
    ...props
  }: CodeBlockProps,
  ref) =>
  {
    const [mouseOver, setMouseOver] = useState(false);
    const [copied, setCopied] = useState(false);
    const onCopied = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        navigator.clipboard.writeText(code);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      },
      [code]
    );

    code = code.trim();
    const lineCount = code.split("\n").length;
    const maxLineWidth = lineCount.toString().length;
    const highlightLines = highlightedRanges?.flatMap(([start, end]) =>
    Array.from({ length: end - start + 1 }, (_, i) => start + i)
    );

    let textSizeClass = "text-xs";
    switch (textSize) {
      case "sm":
        textSizeClass = "text-sm";
        break;
      case "base":
        textSizeClass = "text-base";
        break;
    }

    return (
      <div
        className={cn(
          "relative overflow-x-scroll rounded-md  sm:overflow-hidden",
          removeBorder ? "border-0" : "border border-charcoal-800",
          className
        )}
        style={{
          backgroundColor: theme.plain.backgroundColor
        }}
        ref={ref}
        {...props}
        translate="no">

        {showChrome && <Chrome title={fileName} />}
        {showCopyButton &&
        <TooltipProvider>
            <Tooltip open={copied || mouseOver}>
              <TooltipTrigger
              onClick={onCopied}
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              className={cn(
                "absolute right-3 z-[5] transition-colors duration-100 hover:cursor-pointer",
                showChrome ? "top-10" : "top-4",
                copied ?
                "text-lavender-500" :
                "text-charcoal-500 hover:text-charcoal-300"
              )}>

                {copied ?
              <ClipboardDocumentCheckIcon className="h-5 w-5" /> :

              <ClipboardIcon className="h-5 w-5" />}

              </TooltipTrigger>
              <TooltipContent side="right" className="text">
                {copied ? "Copied" : "Copy"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>}


        <Highlight
          {...defaultProps}
          theme={theme}
          code={code}
          language={language}>

          {({
            className: inheritedClassName,
            style: inheritedStyle,
            tokens,
            getLineProps,
            getTokenProps
          }) =>
          <div
            dir="ltr"
            className="overflow-x-scroll px-3 py-4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-700"
            style={{
              maxHeight: maxCodeHeight
            }}>

              <pre
              className={cn(
                "relative mr-2 font-mono leading-relaxed",
                textSizeClass,
                inheritedClassName
              )}
              style={inheritedStyle}
              dir="ltr">

                {tokens.
              map((line, index) => {
                if (
                index === tokens.length - 1 &&
                line.length === 1 &&
                line[0].content === "\n")
                {
                  return null;
                }

                const lineNumber = index + startingLineNumber;
                const lineProps = getLineProps({ line, key: index });

                let hasAnyHighlights = highlightLines ?
                highlightLines.length > 0 :
                false;

                let shouldDim = hasAnyHighlights;
                if (
                hasAnyHighlights &&
                highlightLines?.includes(lineNumber))
                {
                  shouldDim = false;
                }

                return (
                  <div
                    key={lineProps.key}
                    {...lineProps}
                    className={cn(
                      "flex w-full justify-start transition duration-500",
                      lineProps.className
                    )}
                    style={{
                      opacity: shouldDim ? dimAmount : undefined,
                      ...lineProps.style
                    }}>

                        {showLineNumbers &&
                    <div
                      className={
                      "mr-2 hidden flex-none select-none text-right text-charcoal-500 transition-opacity duration-500 sm:flex"}

                      style={{
                        width: `calc(8 * ${maxLineWidth / 16}rem)`
                      }}>

                            {lineNumber}
                          </div>}


                        <div className="flex-1">
                          {line.map((token, key) => {
                        const tokenProps = getTokenProps({ token, key });
                        return (
                          <span
                            key={tokenProps.key}
                            {...tokenProps}
                            style={{
                              color: (tokenProps?.style?.color as string),
                              ...tokenProps.style
                            }} />);


                      })}
                        </div>
                        <div className="w-4 flex-none" />
                      </div>);

              }).
              filter(Boolean)}
              </pre>
            </div>}

        </Highlight>
      </div>);

  }
);

CodeBlock.displayName = "CodeBlock";

export function Chrome({
  title,
  colour



}: {title?: string;colour?: boolean;}) {
  return (
    <div className="grid h-6 grid-cols-[1fr_auto_1fr] overflow-hidden border-b border-charcoal-800 ">
      <div className="ml-2 flex items-center gap-1">
        <div
          className={cn(
            "h-2 w-2 rounded-full",
            colour ? "bg-red-500" : "bg-charcoal-700"
          )} />

        <div
          className={cn(
            "h-2 w-2 rounded-full",
            colour ? "bg-yellow-500" : "bg-charcoal-700"
          )} />

        <div
          className={cn(
            "h-2 w-2 rounded-full",
            colour ? "bg-green-500" : "bg-charcoal-700"
          )} />

      </div>
      <div className="flex items-center justify-center">
        <div className="text-xs text-charcoal-500">{title}</div>
      </div>
      <div></div>
    </div>);

}